/// <reference path="../../../typings/libraries.d.ts"/>

(function() {

   interface IArticle {
      id: string;
      date: string;
      excerpt: string;
      url: string;
      displayName: string;
   }

   class ArticlesComponent {

      constructor(element: HTMLElement) {
         this.element = element;
         this.element.classList.add('articles-component');
      }

      public element: HTMLElement;
      public articleCountElement: HTMLElement;
      public articles: IArticle[] = null;

      refreshUI() {
         const loadingContentElement = this.element.querySelector('.loading-content') as HTMLElement;
         const noContentMessageElement = this.element.querySelector('.no-content-message') as HTMLElement;
         const articlesElement = this.element.querySelector('.articles') as HTMLElement;
         const buttonElement = this.element.querySelector('.articles-component__button') as HTMLElement;
         const buttonTitle = buttonElement.getAttribute('title') || '';

         if (this.articles === null) {
            Alkami.Dom.showElement(loadingContentElement);
            Alkami.Dom.hideElement(noContentMessageElement);
            Alkami.Dom.hideElement(articlesElement);
            return;
         } else if (this.articles.length === 0) {
            Alkami.Dom.hideElement(loadingContentElement);
            Alkami.Dom.showElement(noContentMessageElement);
            Alkami.Dom.hideElement(articlesElement);
            return;
         }

         Alkami.Dom.hideElement(loadingContentElement);
         Alkami.Dom.hideElement(noContentMessageElement);
         Alkami.Dom.showElement(articlesElement);

         articlesElement.innerHTML = this.articles.map((article) => {
            return `<div class="article">
               <h3 class="article__title"><a href="${article.url}">${article.displayName}</a></h3>
               <span class="article__date">${article.date}</span>
               <div class="article__content-body">${article.excerpt}</div>
               <a class="article__callout-link" href="${article.url}">${Alkami.Localization.SiteText['Global.Content.ReadMore']}</a>
            </div>`;
         }).join('');

         buttonElement.setAttribute('aria-label', `${buttonTitle} ${this.articles.length.toString(10)}`);
      }
   }

   function getEducationalArticles(): Promise<IArticle[]> {
      return new Promise((resolve) => {
         $.ajax({
            url: '/Api/Content/GetEducationalContentImpressions',
            data: { currentWidget: window.currentWidgetName },
            dataType: 'json',
            type: 'GET',
            success: function(data) {
               const content = JSON.parse(data.ContentImpressions || "[]"); //YUCK: Why do we store the array object as a string? O.o
               const articles = content.map((article) => {
                  return {
                     id: article.Id,
                     date: article.Date,
                     displayName: article.DisplayName,
                     excerpt: article.Excerpt,
                     url: `/Content/${article.Id}`
                  };
               });

               return resolve(articles);
            },
            error: function() {
               // Hopefully we never get here.
               return resolve([]);
            }
         });
      });
   }

   function getNewsArticles(): Promise<IArticle[]> {
      return new Promise((resolve) => {
         $.ajax({
            url: "/Api/Content/GetCurrentNews",
            data: { currentWidget: window.currentWidgetName },
            dataType: 'json',
            type: 'GET',
            success: function(data) {
               const content = JSON.parse(data.Content || "[]"); //YUCK: Why do we store the array object as a string? O.o
               const articles = content.map((article) => {
                  return {
                     id: article.Id,
                     date: article.Date,
                     displayName: article.DisplayName,
                     excerpt: article.Excerpt,
                     url: `/Content/${article.Id}`
                  };
               });

               return resolve(articles);
            },
            error: function() {
               // Hopefully we never get here.
               return resolve([]);
            }
         });
      });
   }

   function initArticlesComponent(element: HTMLElement, getter: () => Promise<IArticle[]>) {

      const articlesComponent = new ArticlesComponent(element);
      let getterPromise = null;
      articlesComponent.refreshUI();

      element.addEventListener('expandend', () => {
         if (getterPromise) { return; }

         getterPromise = getter().then((articles) => {
            articlesComponent.articles = articles;
            articlesComponent.refreshUI();
            return articles;
         });
      });

      Alkami.Iris.PopoverComponent.refresh(element.querySelectorAll('[data-popover]'));
   }

   function initArticleAction(li: HTMLElement, buttonOptions: any) {
       const oldButton = li.querySelector('button') as HTMLElement;
       const popover = li.querySelector('.iris-popover') as HTMLElement;
       const buttonAttributes = {
           'data-popover': '',
           'data-placement': 'bottom',
           'data-static-placement': '',
           'data-indicator': 'end',
           'title': oldButton.title,
           'aria-label': oldButton.title
       };

       const actionItem = new Alkami.WidgetHeader.ActionButtonItem(Object.assign({buttonAttributes}, buttonOptions));
       actionItem.element.appendChild(popover);
       actionItem.element.classList.add('legacy-action-item');

       return actionItem;
   }

   function initHelpArticlesAction(li: HTMLLIElement) {
      const oldButton = li.querySelector('a.help') as HTMLAnchorElement;

      const actionItem = new Alkami.WidgetHeader.ActionButtonItem({
          containerId: 'help_articles',
          iconName: 'help',
          text: oldButton.innerText,
          href: oldButton.href,
          buttonAttributes: {
              'title': oldButton.title,
              'aria-label': oldButton.title
          }
      });

      actionItem.buttonElement.classList.add('help-link');
      actionItem.element.classList.add('legacy-action-item');

      return actionItem;
   }

   async function init() {
      const relatedContentPromise = new Promise<HTMLElement>((resolve) => {
         document.body.addEventListener('irisinitstart', () => {
            const element = document.getElementById('related_content');
            if (!element) {
               resolve(null);
               return;
            }

            (element as HTMLElement).remove();
            // element.style.display = 'none';
            resolve(element as HTMLElement);
         });
      });

      const widgetHeaderInitPromise = new Promise<any>((resolve) => {
         document.body.addEventListener( 'widgetHeaderInitBefore', ((event: CustomEvent<any>) => resolve(event.detail.header)) as EventListener);
      });

      try {
         const relatedContent = await relatedContentPromise;
         if(!relatedContent) {
            return;
         }

         const header = await widgetHeaderInitPromise;
         const actionBar = header.actions;

         const educationLI = relatedContent.querySelector('#educational_articles') as HTMLLIElement;
         const newsLI = relatedContent.querySelector('#news_articles') as HTMLLIElement;
         const helpLI = relatedContent.querySelector('#help_articles') as HTMLLIElement;
         const helpLink = relatedContent.querySelector('#help_articles a.help') as HTMLAnchorElement;

         if (educationLI && window.currentWidgetName) {
             const actionItem = initArticleAction(educationLI, {
                 containerId: 'educational_articles',
                 iconName: 'education'
             });

             actionBar.append(actionItem);
             initArticlesComponent(actionItem.element, getEducationalArticles);
         }

         if (newsLI) {
             const actionItem = initArticleAction(newsLI, {
                 containerId: 'news_articles',
                 iconName: 'newspaper'
             });

            actionBar.append(actionItem);
            initArticlesComponent(actionItem.element, getNewsArticles);
         }

         if (helpLI && helpLink) {

             const actionItem = initHelpArticlesAction(helpLI);
             actionBar.append(actionItem);
             $('#help_articles .help-link').instructify();
         }
      } catch (e) {
         console.warn(e);
      }
   }

   init();
})();
